 
.contact-headmain {
    margin-top: 1850px;
    position: relative;
}

.footer-logo .icon {
    color: white;
}

.location-div img {
    width: 50px;
}

.contact-headmain .container .marquee-div-name .flex h1 {
    color: #383232;
    font-family: sans-serif;
    font-weight: 800;
}

.contact-headmain .container .marquee-div-name .fdsafsadfs h3 {
    color: #000000;
}

.contact-form-container {
    border-radius: 5px;
    max-width: 600px;
    /* margin-top: 50px; */
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #070925;
    background-color: #0E0A1B;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.name-email input {
    color: #6a768e;
    font-size: 12px;
}

.phoneinput input {
    color: #6a768e;
    font-size: 12px;
}

input,
textarea {
    margin-bottom: 15px;
    background-color: #131237;
    padding: 10px;
    border-radius: 5px;
}

.text-area {
    height: 120px;
    max-height: 120px;
    min-height: 120px;
    color: #6a768e;
    font-size: 12px;
}

.contact-form-container .contact-form button {
    padding: 8px;
    border-radius: 5px;
    border: none;
    border: 2px solid #007bff;
    background-color: #131237;
    color: white;
    cursor: pointer;
}

.contact-form-container .contact-form button:hover {
    padding: 8px;
    border-radius: 5px;
    border: none;
    border: 2px solid #007bff;
    background-color: #007bff;
    transition: 0.5s;
    color: white;
    cursor: pointer;
}

.how-can {
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 25px;
    color: white;
}

.contact-form button {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #007bff;
    margin-top: 20px;
    border-radius: 5px;
}

.phoneinput .phoneinput {
    width: 50%;
    background-color: wheat;
    border-radius: 5px;
}
 